//import React,{ Component } from "react";
//import videojs from "video.js";
import "../AppCast.css";
import "../App.css";
import useScript from "../components/utilities/useScript";

//const CONTENT_ID = "2a264a851107c835d05d5f21d387fcdd-playlist-98048d1c-d581-7c8f-7f77-6fd97a1d9f5f";
const CONTENT_ID = "2a264a851107c835d05d5f21d387fcdd-playlist-05fd980c-d107-f577-1091-576c855bfe4d";

/* */
function CastNCarrySubcribe () {
    
  const loadPlayer = () => {
    window["dacast"](CONTENT_ID, "videoMedia", {
        height: 500,
    });
    
  };
  
  useScript({
    contentId: CONTENT_ID,
    onLoad: loadPlayer
  });
  
    return (
      <div className="CastNCarry">
        <div className="CastWrapper">
          <div 
            id="videoMedia"
            class="video-js-responsive-container vjs-hd"
            >
            <video 
              //controls 
              //preload="auto" 
              //width="100%"
              //data-setup= '{ }'
            />
          </div>
        </div>    
      </div>
      
    );
}

export default CastNCarrySubcribe;