import React from "react";
import { 
  useAuthenticator, 
} from "@aws-amplify/ui-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import { ProgramTitle } from "./ProgramTitle";
//import ProgramTitleAuth from "./ProgramTitleAuth";
//import { NextEventDateInfo } from "./NextEventDateInfo";
//import { AuthNav } from "./AuthNav";
import AuthNavExtend from "./AuthNavExtend";
import '@fontsource/inter/variable.css';
import "../App.css";

export function WatchShortFilm() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === "authenticated" ? " " : "Loading...";
  return <div level={1}>
  {message}
    <div className="WrappedContent">
      <Container fluid className="AppContainerContent">
         {/***PROGRAM INFO***/}
         {/*
          <Row className="eventDate">
          <Col className="ProgramInfo">
            <ProgramTitleAuth />
          </Col>
        </Row>
        */}
        {/***AUTH CONTENT***/}
        <Row className="MediaRow">
          <Col className="MediaContainer">
          <AuthNavExtend />
          </Col>
        </Row>
      </Container>
    </div>
  </div>;
}

