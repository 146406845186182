import React from "react";
import Button from 'react-bootstrap/Button';
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Media from "react-bootstrap/Media";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ".././App.css";

export function DiscordInfo() {
  return (
    <div level={3}>
      <div className="WrappedContent">
        <Container fluid className="AppContainerContent">
          {/***DISCORD CHANNEL LINK***/} 
          <Row>
            <Col className="ChatAppContent">
              <Media>
                <Media.Body>
                  <Nav
                    /*onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}*/>
                      <Nav.Item>
                        <Nav.Link target="_blank" href="https://discord.gg/hPH5pR3Hbd">
                          <Button
                            //href="https://annyxr.com/"
                           //href="https://discord.gg/hPH5pR3Hbd"
                            target="_blank"
                            variant="outline-primary"
                            size="lg"
                          >
                          DISCORD CHANNEL CHAT
                          </Button>
                        </Nav.Link>
                      </Nav.Item>
                  </Nav>
                </Media.Body>
              </Media>  
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
