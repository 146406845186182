import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './Navbar.css';
import '@fontsource/inter/variable.css';


const NavigationFooter = (props) => {
    console.log(props);
    return (
        <Navbar variant="dark" expand="sm">
            <Container fluid>
                {/*
                <Navbar.Brand href="/">
                    <img
                        width="200px"
                        //height="100%"
                        className="ANNY Virtual Events"
                        src="https://s3.amazonaws.com/animationnights.media/images/NewLogoVE.jpg"
                        alt="ANNY Virtual Events Logo"
                      />
                </Navbar.Brand>
                */}
                    <Nav 
                        className="ml-auto navbar-brand mb-0 h1"
                        style={{ textAlign: 'center' }}
                    >
                        <Row className="footernav">
                            <Col xs="auto">
                                <Nav.Link target="_blank" href="https://animationnights.com"><Button variant="outline-light">VISIT FESTIVAL SITE</Button></Nav.Link>
                            </Col>
                            <Col xs="auto">
                                <Nav.Link target="_blank" href="https://animationnights.com/donations/"><Button variant="outline-light">DONATE TO ANNY</Button></Nav.Link>
                            </Col>
                            <Col xs="auto">
                                <Nav.Link target="_blank" href="https://annyexchange.com"><Button variant="outline-light">CONNECT TO TALENT</Button></Nav.Link>
                            </Col>
                            <Col xs="auto">
                                <Nav.Link target="_blank" href="https://anny.media/help-page/"><Button variant="outline-light">INSTRUCTIONS</Button></Nav.Link>
                            </Col>
                        </Row>
                    </Nav>
            </Container>
        </Navbar>
    )
}

export default NavigationFooter;
