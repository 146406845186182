import React from "react";
import { 
  //BrowserRouter as Router,
  BrowserRouter, 
  Routes, 
  Route, 
  Navigate
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import { 
  Authenticator, 
} from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import Container from "react-bootstrap/Container";
import AppNavCopyright from "./components/Copyright";
import NavigationFooter from './components/Navbar/NavbarFooter';
import { WatchShortFilm } from "./components/WatchShortFilm";
import { RequireAuth } from "./RequireAuth";
import { Login } from "./components/Login";
import { Annyxr } from "./components/Annyxr";
import { ErrorPage } from "./components/ErrorPage";
import { ANNYExchangeClient } from "./components/ANNYExchangeClient";
import { WatchProgram } from "./components/WatchProgram";
//import Subscriber from "./components/routes/Subscriber";
//import Creator from "./components/routes/Creator";
//import Attendee from "./components/routes/Attendee";
//import Admin from "./components/routes/Admin";
import { NavLayout } from "./components/NavLayout";
import { DiscordInfo } from "./components/DiscordInfo";
//import VotingApp from "./components/VotingApp";
import VotingApp from "./components/VotingApp-v1";
import '@fontsource/inter/variable.css';
import '@aws-amplify/ui-react/styles.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

Amplify.configure(awsExports);

function AnnyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NavLayout />}>
          <Route index element={<Login />} />
          <Route path="/login" element={ <Login /> } />
          <Route path="/home" element={ <Navigate to="/home" />  } />
          <Route path="/errorpage" element={ <ErrorPage /> } />
          <Route path="/redirect" element={ <Navigate to="/errorpage" /> } />
          {/*
          <Route path="/admin" element={ <Admin /> } />
          <Route path="/subscriber" element={<RequireAuth><Subscriber /></RequireAuth> } />
          */}
          <Route path="/votingapp" element={ <RequireAuth><VotingApp /></RequireAuth> } />
          <Route path="/watchshortfilm" element={ <RequireAuth><WatchShortFilm /></RequireAuth> } />
          <Route path="/annyxr" element={ <RequireAuth><Annyxr /></RequireAuth> } />
          <Route path="/annyexchangeclient" element={ <RequireAuth><ANNYExchangeClient /></RequireAuth> } />
          <Route path="/watchprogram" element={ <RequireAuth><WatchProgram /></RequireAuth> } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <div className="WrappedContent">
      <Container fluid className="AppContainerContent">
        <div className="bgRoom">
          <Authenticator.Provider>
            <AnnyRoutes />
          </Authenticator.Provider>
          <div className="footernav">
          <DiscordInfo />
          </div>
          <div className="footernav">
          <NavigationFooter />
          </div>
          <div className="footernav">
          <AppNavCopyright />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default App;
 
