import React from "react";
import Button from 'react-bootstrap/Button';
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Media from "react-bootstrap/Media";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ".././App.css";

export function HubsInfo() {
  return (
    <div level={3}>
      <div className="WrappedContent">
        <Container fluid className="AppContainerContent">
          {/*** HubsInfo ***/}
          <Row className="MediaRow">
            <Col className="MediaContainer">
              <div className="MediaWrapper">
                <Nav>
                  <Nav.Link target="_blank" href="https://annyxr.com/">
                    <Button
                    //ref="https://annyxr.com/L8sR3fi?hub_invite_id=nH3ysCC"
                    target="_blank"
                    variant="outline-primary"
                    size="lg"
                    >
                    VISIT US IN THE VR SPACE
                  </Button>
                  </Nav.Link>
                </Nav>  
              </div>
            </Col>
          </Row>
            <Col>
              <div>
                <p>The portal page will open in a new window. Give space a second to load. (See you inside!)</p>
              </div>
            </Col>
          <Row>
          </Row>
          <Row>
            <Col className="ANNYBot">
              <Media>
                <div className="PosterImage">
                <img
                  width={200}
                  //height={169}
                  //width="100%"
                  //height="100%"
                  loading="lazy"
                  //src="./images/ANNYBOT-is-waiting-for-you-300x169.png"
                  src="https://s3.amazonaws.com/animationnights.media/images/e06a182a-7060-40a5-89e3-b7ed4c0671b5.png"
                  alt="ANNYBot is waiting for you."
                />
                </div>
              </Media>
            </Col>
          </Row>
          <Row>
            <Col className="ANNYBot">
              <Media>
                <Media.Body>
                  <p>
                    ANNYBOT is waiting for you. (No headset is required.
                    Accessible via PC/MAC/Mobile.)
                  </p>
                </Media.Body>
              </Media>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
