import React, { 
  //useState, 
  useEffect} from "react";
import { useNavigate, useLocation } from "react-router";
import { 
  Image, 
  View, 
  useAuthenticator, 
  Authenticator, 
  useTheme 
} from "@aws-amplify/ui-react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Home } from "../components/Home";
import '@fontsource/inter/variable.css';
import "@aws-amplify/ui-react/styles.css";
import './Navbar/Navbar.css';
import '../App.css';


export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  
  
  //Authentication Check
  const AppUser = () => {
  const { route } = useAuthenticator(context => [context.route]);

  // Use the value of route to decide which page to render
  return route === "authenticated" ? <Home /> : <Authenticator />;
};

  let from = location.state?.from?.pathname || "/";
 
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  
// Amplify Login Custom 
const components = {
  
  Header() {
    const { tokens } = useTheme();

    return (
      <View 
        textAlign="center" 
        padding={tokens.space.large}>
        <Image
          alt="ANNY Virtual Events Logo"
          src="https://s3.amazonaws.com/animationnights.media/images/NewLogoVE.jpg"
          width="100%"
          height="100%"
        />
      </View>
    );
  }
};

return (
    <View className="auth-wrapper">
      <div className="WrappedContent">
        <Container fluid className="AppContainerContent">
            <Authenticator
              //formFields={formFields}
              components={components}
              //errorMessage={map}
              initialState="signIn" 
              className="Authenticator"
            >
              {({ signOut, user }) => (
              <>
                <Row className="loginStatusMessage">
                  <Col md="auto" className="AuthContent">
                    <div>Hello, {user.username}.</div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Home />
                  </Col>
                </Row>
                <Row>
                  <Col className="LogoutButtonContainer">
                    <button 
                      className="signOutButton" 
                      variant="outline-light"
                      onClick={signOut}>
                        SIGN OUT
                    </button>
                  </Col>
                </Row>
                
                </>
               ) 
              }
            </Authenticator>
        </Container>
      </div>
    </View>
  );
}