import React from 'react';
import { 
  useAuthenticator, 
} from "@aws-amplify/ui-react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HubsInfo } from "./HubsInfo";
import '@fontsource/inter/variable.css';
import "../App.css";

export function Annyxr() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === "authenticated" ? " " : "Loading...";
  return <div level={1}>{message}
    <div className="WrappedContent">
      <Container fluid className="AppContainerContent">
        <Row className="MediaRow">
          <Col className="MediaContainer">
            <div className="MediaWrapper">
              <div className="PosterImage">
                <Nav>
                  <Nav.Link target="_blank" href="https://annyxr.com/">
                    <img
                        width="100%"
                        height="100%"
                        loading="lazy"
                        className="ANNY Virtual Events"
                        src="https://s3.amazonaws.com/animationnights.media/images/ANNYVirtualEvents/New+Space1.png"
                        alt="ANNY Virtual Events"
                      />
                    </Nav.Link>
                  </Nav>  
                </div>
              </div>
            </Col>
          </Row>
          {/***HUBS INFO***/}
          <Row className="MediaRow">
            <Col className="MediaContainer">
              <HubsInfo />
            </Col>
          </Row>
      </Container>
    </div>
  </div>;
}
