import React, { 
  //Component, 
  useEffect, 
  useState,
  //useRef
  } from 'react';
import base from "./Airtable";
//import fetch from "node-fetch";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Media from "react-bootstrap/Media";
//import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
//import styled from "styled-components";
import { FaVoteYea } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import ".././App.css";


const VotingApp = () => {
  const [items, setItems] = useState([]);
  //state items
  const [loading, setLoading] = useState(true);
  
  const getRecords = async () => {
    const records = await base("Voting")
      .select({})
      .firstPage()
      .catch(err => console.log(err));
      //console.log(records)
      
    //iterate over records, access each record
    const newItems =  records.map(record => {
      //return from function, from each record
      const { id, fields } = record;
      //return a new object
      return { id, fields };
    });
    setItems(newItems);
    setLoading(false);
  };
  
  //async waiting for the promise to settle setLoading to true to show "loading...", iterate over state items and update the one with the matching id, spread out all items from state and map over them, overwrite +1 if id matches
  const giveVote = async id =>{
    setLoading(true);

    const tempItems = [...items].map((item)=>{
      if(item.id === id){
        let {id, fields} = item;
        fields = { ...fields, votes: fields.votes + 1 };
        return { id, fields };
      } else {
        return item ;
      }
    });
    
    const records = await base("Voting")
      .update(tempItems)
      .catch(err => console.log(err));

    const newItems =  records.map(record => {
      const { id, fields } = record;
      return { id, fields };
    });
      setItems(newItems);
      setLoading(false);
  };
  
  const takeVote = async id =>{
    setLoading(true);

    const tempItems = [...items].map((item)=>{
      if(item.id === id){
        let {id, fields} = item;
        fields = { ...fields, votes: fields.votes - 1 };
        return { id, fields };
      } else {
        return item ;
      }
    });
    
    const records = await base("Voting")
      .update(tempItems)
      .catch(err => console.log(err));

    const newItems =  records.map(record => {
      const { id, fields } = record;
      return { id, fields };
    });
      setItems(newItems);
      setLoading(false);
  };
  
  //if loading is true, display "loading...", otherwise render unordered list & iterate over items, access it in a callback function, destructuring the values
  useEffect(()=>{
    getRecords();
  }, []);
  //console.log(items);
  
    const [disable, setDisable] = useState(false);
    
    //const buttonRef = useRef(null);
    
    //const [isHovered, setHover] = useState(false);
    //const [checkMark, setCheckmark] = useState(false);
    const [buttonText, setButtonText] = useState("VOTE");
  
    // function handleClick () {
    //       //setButtonText("THANK YOU");
    //       setCheckmark(setHover(true));
    //       //console.log('handleClick clicked!');
    //     };
        
    // function handleReset () {
    //       //setButtonText("VOTE");
    //       setCheckmark(setHover(false));
    //       //console.log('handleClick clicked!');
    //     };
  
    // const handleClick = event => {
    //       event.preventDefault();
    //       //event.currentTarget.disabled = true;
    //       setButtonText("SELECTED");
    //       checkMark(setHover(true));
    //       //console.log('handleClick clicked!');
    //     };
      
    //   const handleReset = event => {
    //       event.preventDefault();
    //       buttonRef.current.disabled = false;
    //       setButtonText("VOTE");
    //       setCheckmark(setHover(false));
    //       //console.log('handleReset clicked!');
    //     };
    
  
  return (
    <div className="WrappedContent">
      <Container fluid className="AppContainerContent">
            <Row className="VotingRow">
              <Col className="VotingInfo">
                <Media>
                  <Media.Body>
                    <div className="ButtonContent">
                      <ul>
                        <li>
                          <h1>Audience Choice Poll</h1>
                        </li>
                        <li>
                          <h3>Select Your Favorite Film!</h3>
                        </li>
                        <li>
                          <p>(Click the blue VOTE button for the film of your choice.)</p>
                        </li>
                        <li>
                          <p>Your selection will help curate our ANNY Audience Choice Program.</p>
                        </li>
                      </ul>
                    </div>
                  </Media.Body>
                </Media>
              </Col>
            </Row>
            <div class="row justify-content-center">
              <div className="cardColStacked col-md-8">
                <div className="card"> 
                  <div className="cardContainerStacked">
                    {items.map(item => { const {id, fields:{title, description, votes, imageURL, country}, } = item;
                      //console.log(Name);
                      return <div className="cardStacked">
                      {/*  
                      {isHovered && (
                      <Image className="ImgOverlay" 
                             src="checkmark.png" 
                             />
                              )}
                      */}
                      <img className="card-img-top" 
                            src={imageURL[0].url} 
                            alt="Movie poster"
                            />
                        <Button //ref={buttonRef}   
                                //disabled={loading?true:false}
                                size="lg"
                                disabled={disable}
                                block
                                variant="primary" 
                                onClick={() => {
                                giveVote(id);
                                setButtonText("Thanks for your vote.");
                                //setCheckmark(setHover(true));
                                setDisable(true);
                                }}
                                >{buttonText} <FaVoteYea />
                        </Button>
                        <div className="card-body">
                          <h5 className="card-title">{title}</h5>
                          <p className="card-text">{description}</p>
                          <p className="card-text">
                            <small className="text-muted">{country}</small>
                          </p>
                          <p className="card-footer">
                            Vote Count : {votes}
                          </p>
                        </div>
                        {/*
                        <div className="voting-buttons">
                          <Button 
                            variant="outline-dark"
                            //disabled={loading?true:false}
                            onClick={() => {
                            takeVote(id);
                            setButtonText("VOTE");
                            //setCheckmark(setHover(false));
                            setDisable(false);
                            }}
                            >
                            REMOVE VOTE
                            </Button>
                        </div>
                        */}
                      </div>;
                    })}
                  </div>
                </div>
              </div>
            </div>
      </Container>
    </div>
  );
};

export default VotingApp;