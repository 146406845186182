import React from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';
//import Row from "react-bootstrap/Row";
//import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
//import Button from "react-bootstrap/Button";
//import { NextEventDateInfo } from "../components/NextEventDateInfo";
import { ProgramTitle } from "../components/ProgramTitle";
import ".././App.css";

export function ProgramTitleAuth({ user }) {
  
  const UserType1 = (user.signInUserSession.idToken.payload?.['cognito:groups']?.includes('ANNYSubscribers'));
  //const UserType2 = (user.signInUserSession.idToken.payload?.['cognito:groups']?.includes('ANNYFilmmakers'));
  //const UserType3 = (user.signInUserSession.idToken.payload?.['cognito:groups']?.includes('ANNYTeam'));
  
  return (
    <div level={3}>
      <div className="WrappedContent">
        <Container fluid className="AppContainerContent">
            {(() => {
            if (UserType1) {
              return (
                <div>
                {/* SUBSCRIBER */}
                {/***PROGRAM INFO***/}
                   {/*
                    <Row>
                      <NextEventDateInfo />
                    </Row>
                    */}
                    <ProgramTitle />
                {/* SUBSCRIBER */}
                </div>
              );
            } else {
              return (
                <div>
                    {/* NOT SUBSCRIBER */}
                    {/***PROGRAM INFO***/}
                      <ProgramTitle />
                    {/* USER */}
                </div>
              );
            }
        })()}
        </Container>
      </div>
    </div>
  );
}

export default withAuthenticator(ProgramTitleAuth);