import React from "react";
import ReactDOM from "react-dom";
import { Amplify} from "aws-amplify";
import awsExports from "./aws-exports";
import '@fontsource/inter/variable.css';
import "./index.css";

import App from "./App";
//import App from './AppOffline';
//import App from './AppCast';
//import App from './ANNYApp';

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById("root")
);

