import React from 'react'
import { 
  withAuthenticator, 
  //useAuthenticator 
  } from '@aws-amplify/ui-react';
//import { API } from 'aws-amplify';
import { 
  //Outlet, 
  useNavigate 
  } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
//import Button from "react-bootstrap/Button";
//import { Flex } from "reflexbox";
//import  CastNCarry  from "../CastNCarry/CastNCarry";
import { WatchProgram } from "../components/WatchProgram";
//import  CastNCarrySubscribe  from "../CastNCarry/CastNCarrySubscribe";
import { EventDateInfo } from "../components/EventDateInfo";
//import { NextEventDateInfo } from "../components/NextEventDateInfo";
//import VotingApp from "../components/VotingApp";

function AuthNavExtend({ user }) {

    const navigate = useNavigate();
    
    const UserType1 = (user.signInUserSession.idToken.payload?.['cognito:groups']?.includes('ANNYSubscribers'));
    const UserType2 = (user.signInUserSession.idToken.payload?.['cognito:groups']?.includes('ANNYFilmmakers'));
    const UserType3 = (user.signInUserSession.idToken.payload?.['cognito:groups']?.includes('ANNYTeam'));
    
    return (
    <div className="WrappedContent">
     <Container fluid className="AppContainerContent">
        {(() => {
            if (UserType1) {
              return (
                <div>
                <Row className="customMessage">
                <p>Welcome, {user.username}.</p>
                </Row>
                {/* SUBSCRIBER */}
                    <Row>
                      <Col>
                        {/*  
                        <Button 
                              className="navButton" 
                              variant="outline-light" 
                              onClick={() => navigate("/annyexchangeclient")}
                              //disabled
                              >
                            SPECIAL PROGRAM
                         </Button>
                         */} 
                         <Nav>
                          {/*  
                          <Nav.Link onClick={() => navigate("/annyexchangeclient")}>
                          */}
                            <img
                                width="100%"
                                height="100%"
                                loading="lazy"
                                className="ANNY Virtual Events"
                                src="https://s3.amazonaws.com/animationnights.media/images/annyexchangelogo.jpg"
                                alt="ANNY Virtual Events"
                              />
                            {/*    
                            </Nav.Link>
                            */}
                          </Nav> 
                      </Col>
                      <Col>
                         {/* 
                         <Button 
                            variant="outline-light" 
                            className="navButton" 
                            onClick={() => navigate("/watchprogram")}  
                            //disabled
                            >
                            LATEST PROGRAM
                         </Button>
                         */}
                        <Nav>
                          {/*   
                          <Nav.Link onClick={() => navigate("/watchprogram")}>
                          */}
                            <img
                                width="100%"
                                height="100%"
                                loading="lazy"
                                className="ANNY Virtual Events"
                                src="https://s3.amazonaws.com/animationnights.media/images/NewLogo.jpg"
                                alt="ANNY Virtual Events"
                              />
                          {/*  
                          </Nav.Link>
                          */}
                        </Nav> 
                      </Col>
                     
                    </Row>
                {/* SUBSCRIBER */}
                </div>
              );
            } else if (UserType2) {
              return (
                <div>
                <Row className="customMessage">
                <p>Welcome, {user.username}. We glad you are here. Enjoy the program!</p>
                </Row>
                {/* FILMMAKER */}
                 <Row>
                  <Col>
                    {/*
                    <WatchProgram />
                    */}
                    <EventDateInfo />
                    
                   </Col>
                  </Row>
                {/* FILMMAKER */}
                </div>
              );
            } else if (UserType3) {
              return (
                <div>
                <Row className="customMessage">
                <p>Welcome, {user.username}. We are glad to have you on the ANNY Team. See you soon!</p>
                </Row>
                    {/* ANNY TEAM */}
                    <Row>
                      <Col>
                        
                        <WatchProgram />
                        {/*
                        <EventDateInfo />
                        */}
                      </Col>
                    </Row>
                    {/* ANNY TEAM */}
                </div>
              );
            } else {
              return (
                <div>
                <Row className="customMessage">
                <p>Welcome, {user.username}. We glad you are here. Enjoy the program!</p>
                </Row>
                    {/* USER */}
                    <Row>
                      <Col>
                        {/*
                        <WatchProgram />
                        */}
                        <EventDateInfo />
                        
                      </Col>
                    </Row>
                    {/* USER */}
                </div>
              );
            }
        })()}
     </Container>
    </div>
  );
    
}

export default withAuthenticator(AuthNavExtend);