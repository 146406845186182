import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ".././App.css";


export default function AppNavCopyright() {
    const current = new Date();
    //const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    const date = `${current.getFullYear()}`;

    return(
          <div>
            <Container>
              <Row>
                <Col className="FooterInfo">
                  <p>
                    <span dangerouslySetInnerHTML={{ "__html": "&copy;" }} /> 2015-{date} eyesnare inc. All Rights Reserved.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
    );
}