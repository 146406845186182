import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Slideshow from "../components/Slideshow";
//import { EventDateInfo } from "../components/EventDateInfo";
import { NextEventDateInfo } from "../components/NextEventDateInfo";
//import Reviews from "../components/Reviews";
//import { Login } from "../components/Login";
import '@fontsource/inter/variable.css';
import ".././App.css";

export function Home() {
  

  return (
    <div level={3}>
      <div className="WrappedContent">
        <Container fluid className="AppContainerContent">
            {/*** HOME CONTENT ***/}
            <Row>
              <Col>
                <div className="homeMessage">  
                <h1> Welcome to ANNY Virtual Events!</h1>
                <p>Thank you for visting.</p>
                <p>Use the navigation menu above ^ to watch films and join our web based virtual reality events.</p>
                <p>Films will be available on the day of the event.</p>
               </div>
              </Col>
            </Row>
            {/*** EVENT INFO ***/}
            <Row className="MediaRow">
              <Col className="MediaContainer">
                <NextEventDateInfo />
              </Col>
            </Row>
            <Row className="MediaRow">
            <div className="slideshowContainer">
              <Slideshow />
            </div>
            </Row>
        </Container>
      </div>
    </div>
  );
}
