const people = [
     {
    id: 1380,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "LF",
    title: "Attendee",
    quote: "great line-up. totally enjoyable."
    },
    {
    id: 1382,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "Anonymous",
    title: "Attendee",
    quote: "This was fabulous and the line up was beautiful. Really enjoyed it. It broke the monotony of uninspired media. A valuable opportunity for great work to be seen."
    },
    {
    id: 1388,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "KR",
    title: "Attendee",
    quote: "Please continue offering ANNY online, especially if you go back to in person events."
    },
    {
    id: 1398,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "Anonymous",
    title: "Attendee",
    quote: "Thanks for continuing to stream these films.  "
    },
    {
    id: 1405,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "Anonymous",
    title: "Attendee",
    quote: "I'm extremely thankful for the ANNY events. I love the creativity and originality. "
    },
    {
    id: 1427,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "SW",
    title: "Attendee",
    quote: "Been a fan of this event since it's inception. Used to go too the live event at the Seaport every month. Although, my main residence is in Georgia now i'am glad that your online festival allows me to enjoy the show just as I did when I lived in NY. Keep up the great work and I'am looking forward to submitting my film to your festival once it's completed "
    },
    {
    id: 1431,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "L",
    title: "Attendee",
    quote: "Many thanks! Am torn - use to love the in person screenings and would like to do in person screenings again - but the online events are so convenient! Appreciate that you are considering both!  "
    },
    {
    id: 1438,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "N",
    title: "Attendee",
    quote: "It was very exciting event! Thanks artists, organizers and participants. I live in Japan so I'm tired of trends of designs for characters, touches of drawings and ways of using colours in Japanimation industry. That's why I was attracted by this fantastic event where different types of animaters gather. I was impressed by all. Thank you, I'd like to come here again if the next online event is held."
    },
    {
    id: 1443,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "PA",
    title: "Attendee",
    quote: "This month's selections were ALL SO GOOD that it was hard to choose a favorite. Bravo to all the artists and ANNY curators.  Thank you."
    },
    {
    id: 1452,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "Anonymous",
    title: "Attendee",
    quote: "Thank you so much for doing this. We watch every month (and yes I have donated)."
    },
    {
    id: 1454,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "JM",
    title: "Attendee",
    quote: "If you go with an on-site programming process again, I'd be happy to pay an online viewing event fee.  The trip to NY is nothing I can coordinate with ease, alas...."
    },
    {
    id: 1455,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "A",
    title: "Attendee",
    quote: "ANNY is a wonderful program that me and my boyfriend enjoy very much. Thank you for sharing with his animation from around the world."
    },
    {
    id: 1457,
    image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
    name: "MG",
    title: "Attendee",
    quote: "I love your animation nights! "
    },
  {
  id: 497,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "A",
  title: "Attendee",
  quote: "Very nice event. Great selection of films"
  },
  {
  id: 496,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "SB",
  title: "Attendee",
  quote: "Keep up the excellent online Animation Nights. I really enjoy your programming but live too far away to easily attend in-person events."
  },
  {
  id: 491,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "K",
  title: "Attendee",
  quote: "I live in Michigan and LOVE being able to participate. Thank you so much!!"
  },
  {
  id: 488,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "A",
  title: "Attendee",
  quote: "Keep up with the amazing work. I look forward every month to this event and I'm so glad I can follow it even if I'm in Italy! Thank you!"
  },
  {
  id: 484,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "L",
  title: "Attendee",
  quote: "Love the convenience of the online screenings. Was difficult at first getting log in credentials, actually logging in, and navigating - but it has been very smooth recently. That said, I do miss the vibes of in person screenings. Tough call.  I hope you all are doing well financially and otherwise. Very much appreciate you all! Thank you for the screenings!"
  },
  {
  id: 478,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "CB",
  title: "Attendee",
  quote: "Yvonne did a wonderful job welcoming and greeting new occupants in the Web XR. I felt welcome and if I had amy questions, they were happy to answer. I'll be back for the next event."
  },
  {
  id: 474,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "CAM",
  title: "Attendee",
  quote: "I enjoy the online presentations. But I also would get back to live events."
  },
  {
  id: 469,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "JL",
  title: "Attendee",
  quote: "I enjoyed this set of films more than some of the others. A lot of entertaining and though provoking stories and images. Great music. Usually, for me, the shorter animations are the more I like them. I wish some of my friends from other lands would sign up to see them. Thank you very much."
  },
  {
  id: 465,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "SB",
  title: "Attendee",
  quote: "Was there a theme to last night's presentation?  All the films seemed to be about death."
  },
  {
  id: 463,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "DG",
  title: "Attendee",
  quote: "Please don't stop the online events. It is lovely to watch all the way from Africa"
  },
  {
  id: 457,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "D",
  title: "Attendee",
  quote: "Thank you for this program. You guys are great!"
  },
  {
  id: 436,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "NG",
  title: "Attendee",
  quote: "Make sure you don't forget about the Manhattan-ites. Also it would be great if there was a picnic feel to the in-person events where people ate communally to get to know one another.  Or else a cocktail party feel with tall cocktail tables that encouraged people to stand up and talk to people."
  },
  {
  id: 432,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "KR",
  title: "Attendee",
  quote: "Please continue online events even after you resume in person events! They're great for scheduling, accessibility, and for introducing friends to the event series!"
  },
  {
  id: 431,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "I loved the online format. It made it so much easier and enjoyable to watch and I think I was able to pay better attention in many ways. The whole experience was awesome. Thank you!"
  },
  {
  id: 423,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "SB",
  title: "Attendee",
  quote: "Please continue the online events. I'm going to local events again but am outside the NY area so the virtual programs are ideal. Thanks!"
  },
  {
  id: 422,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "JM",
  title: "Attendee",
  quote: "Even if you go to in-person only events, consider some form of online experience, if only for the quasi-shut-ins who aren't always able to negotiate a Big Apple roadtrip!  Many thanks... great programming."
  },
  {
  id: 415,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "CML",
  title: "Attendee",
  quote: "Hi, I love your online events. I live in Hawaii so online is the best for me.  I was out of the country during the most recent ANNY virtual event so didn't give you feedback.  Please keep me on your viewing list! i love seeing the animation shows.  Take good care!"
  },
  {
  id: 411,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "A",
  title: "Attendee",
  quote: "Good job! Can't wait to see the next program ;)"
  },
  {
  id: 387,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "MM",
  title: "Attendee",
  quote: "I don’t live in NY, and am am blown away by the quality of these screenings, so I am very grateful for the online access! Thank you! I hope to explore the discord and xr areas next month and chat with the community more"
  },
  {
  id: 381,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "MG",
  title: "Attendee",
  quote: "Great short films! Please continue and invite me again please!"
  },
  {
  id: 379,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "You all rule."
  },
  {
  id: 363,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "JL",
  title: "Attendee",
  quote: "Living in Kansas, I would not be able to attend in person events. Thank you for the great program. "
  },
  {
  id: 359,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "It was a great selection of animation, thank you"
  },
  {
  id: 350,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "S",
  title: "Attendee",
  quote: "Very enjoyable programming. I love out of your area so hope you can continue to do virtual programming even after you resume live events."
  },
  {
  id: 348,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "Very fun.  We love shorts, esp. animated shorts. Thank you."
  },
  {
  id: 341,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "CML",
  title: "Attendee",
  quote: "I hope you can continue to show ANNY online.  I live in Hawaii!!!"
  },
  {
  id: 339,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "Great show."
  },
  {
  id: 336,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "I live in Los Angeles, so, I will not be going to “ in person events “ in NYC.😺"
  },
  {
  id: 335,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "JM",
  title: "Attendee",
  quote: "That was an incredible collection of both wild and thoughtful films - animation will save this world, if only to give it space to exhale."
  },
  {
  id: 333,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "KR",
  title: "Attendee",
  quote: "I think I would attend in person again, but I really love the virtual events. I hope ANNY keeps them available."
  },
  {
  id: 329,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "CH",
  title: "Attendee",
  quote: "No! I love being a part of ANNY virtual events. You make me so happy. Please keep-up with this fantastic, awesome and out of this world virtual events!!!    "
  },
  {
  id: 296,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "L",
  title: "Attendee",
  quote: "I would like to add comments of what I liked about the animations. I don't know if that additional information would be helpful, but at times I see something that makes me say, Wow! I usually don't go to the virtual rooms because Sundays are busy comic drawing days for me. Thank you for the programs. "
  },
  {
  id: 294,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "We love ANNY!!!!"
  },
  {
  id: 288,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "I enjoyed the program 😺"
  },
  {
  id: 278,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "Very interesting. Please do more."
  },
  {
  id: 277,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "SW",
  title: "Attendee",
  quote: "Keep up the great work"
  },
  {
  id: 276,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "C",
  title: "Attendee",
  quote: "Amazing work, thank you!"
  },
  {
  id: 273,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "LF",
  title: "Attendee",
  quote: "I will look for a way to add this to our movie club roster.  Really enjoyed it."
  },
  {
  id: 263,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "It's a wonderful program.  Thanks for the opportunity to see fantastic creativity from around the world.  "
  },
  {
  id: 255,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "JL",
  title: "Attendee",
  quote: "Great service. Great films. Really enjoyed them. "
  },
  {
  id: 254,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "I enjoy the programs very much and look forward to them every month.  I haven't done any animation myself for quite sometime and it is very nice to see what other people are doing with the medium."
  },
  {
  id: 250,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "The ANNY this year is great!"
  },
  {
  id: 248,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "excellent program!!!"
  },
  {
  id: 244,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "DJ",
  title: "Attendee",
  quote: "more please"
  },
  {
  id: 241,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "I'm having a rough time now and appreciate an occasional escape into other worlds through animation. Thank you. "
  },
  {
  id: 234,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "RM",
  title: "Attendee",
  quote: "BEST PROGRAMMING of any group Ive watched hear at leat 4 BRILLIANT pieces and many great ones"
  },
  {
  id: 233,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "I enjoy your Anny Programs very much and look forward to it every month."
  },
  {
  id: 231,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "SA",
  title: "Attendee",
  quote: "So amazing "
  },
  {
  id: 225,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "JMC",
  title: "Attendee",
  quote: "Keep up the amazing work. You are doing something important for the art, craft, and people of animation. "
  },
  {
  id: 220,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "D",
  title: "Attendee",
  quote: "Thanks for existing!"
  },
  {
  id: 213,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "It seems like this is a monthly event.  I hope to receive invitations for future events.  I was VERY pleased."
  },
  {
  id: 194,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "I really really love being able to participate and watch from home! I live in Michigan and have been a fan of ANNY from a distance. I'm super happy to be able to watch your screenings, you've helped me find some truly beautiful and interesting work. Thank you so much!"
  },
  {
  id: 190,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "EÍAV",
  title: "Attendee",
  quote: "It was a great experience and the people working were so friendly. Keep it up!"
  },
  {
  id: 187,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "EJ",
  title: "Attendee",
  quote: "I’m sure I will as I continue to watch, but this was my first time, and I enjoyed it thoroughly.  I also animate, so perhaps in the future I’ll be able to contribute (artistically).  In the future I’ll make use of the virtual event space. Thanks, and keep up the great work!"
  },
  {
  id: 180,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "JL",
  title: "Attendee",
  quote: "A wonderful program. Thank you very much."
  },
  {
  id: 158,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "JL",
  title: "Attendee",
  quote: "What great program and what a wonderful organization! Keep up the great work."
  },
  {
  id: 120,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "S",
  title: "Attendee",
  quote: "Great experience and wonderful/stunning/moving films. "
  },
  {
  id: 118,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "JL",
  title: "Attendee",
  quote: "It was a terrific program. Hope to see and participate more."
  },
  {
  id: 109,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "A",
  title: "Attendee",
  quote: "I love your work. I truly do. I've been coming to almost all of your events. Almost religiously. (I was front line in your inaugural screening, if you see the photos of that night, in September of 2015)"
  },
  {
  id: 100,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "J",
  title: "Attendee",
  quote: "The shorts screening was an excellent selection, I watched with a friend and we were both incredibly blown away by the films!"
  },
  {
  id: 99,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "J",
  title: "Attendee",
  quote: "Keep them coming, I look forward to it every month!"
  },
  {
  id: 96,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "Programs were varied and enjoyable. Looking forward to future events"
  },
  {
  id: 87,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "KR",
  title: "Attendee",
  quote: "I'm quite grateful that ANNY has been offering online events like this for the past several months. I've been watching them with friends, and I look forward to them every month. Thank you again for screening your fantastic showcases!"
  },
  {
  id: 60,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "I appreciated the  programing from light hearted comedy, easing into darker drama. I look forward to attending future events."
  },
  {
  id: 46,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "Big fun. Thanks for including us ex-NYers on the west coast. "
  },
  {
  id: 43,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "HH",
  title: "Attendee",
  quote: "Thanks for keeping this going!!! You give me something to look forward each month!"
  },
  {
  id: 42,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "AW",
  title: "Attendee",
  quote: "Thanks for an inspiring show. Looking forward to future Virtual Events and will make the trip to Brooklyn as able."
  },
  {
  id: 39,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "MR",
  title: "Attendee",
  quote: "So far I love Animation Nights New York "
  },
  {
  id: 32,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "K",
  title: "Attendee",
  quote: "I live in Michigan and enjoy following ANNY - so I was sooo excited to be able to participate from home! This was honestly a highlight of my year, all of the films were so good and it was great to have the film screening experience from the comfort of my home. Great selection, easy to join. Thank you so much! :) "
  },
  {
  id: 31,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "More please! Loved it."
  },
  {
  id: 27,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "S",
  title: "Attendee",
  quote: "This was awesome. I spontaneously decided it would be fun to attend a virtual film festival, and I found yours through EventBrite. So glad I did! Thank you!!"
  },
  {
  id: 20,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "So excited that this has started again!! Can't wait for more!"
  },
  {
  id: 16,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "AP",
  title: "Attendee",
  quote: "So excited that is back! Since you guys stopped having the events at downtown Manhattan, I haven't been able to join in Greenpoint and I've truly missed it "
  },
  {
  id: 15,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "I loved the range of animation. I'm viewing from Indiana, where we occasionally get to see the Oscar nominations, but not much more. I hope this event continues."
  },
  {
  id: 12,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "AMM",
  title: "Attendee",
  quote: "Can't wait to see next month's program."
  },
  {
  id: 11,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "KR",
  title: "Attendee",
  quote: "Thanks so much for hosting a virtual event this month! I hope this becomes a regular occurrence. I missed going to anny in person and the virtual event was lots of fun."
  },
  {
  id: 10,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "Great selection of films.  The curation was far and above most animation selections."
  },
  {
  id: 6,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "Anonymous",
  title: "Attendee",
  quote: "So glad you’re doing this online! It was an excellent program "
  },
  {
  id: 5,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "P",
  title: "Attendee",
  quote: "Thank you for working through the snafus!  I loved when the films were shown downtown on Maiden Lane. Enjoyed seeing the animation again.  You're like a beloved, old friend!"
  },
  {
  id: 4,
  image: "https://s3.amazonaws.com/animationnights.media/images/personIcon.jpg",
  name: "TH",
  title: "Attendee",
  quote: "The animations demonstrated a huge degree of creativity and technical accomplishment. Bravo!"
  },
 ];
export default people;