import React, 
    { 
    //useState, 
    //useRef,
    //Component,
    //useEffect,
    } from "react";
//import fetch from 'node-fetch';
//import Airtable from 'airtable';
//import videojs from "video.js";
import { 
  useAuthenticator, 
  } from "@aws-amplify/ui-react";
import Container from "react-bootstrap/Container";
//import Row from "react-bootstrap/Row";
//import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
//import { ProgramTitle } from "./ProgramTitle";
//import ProgramTitleAuth from "./ProgramTitleAuth";
//import { NextEventDateInfo } from "./NextEventDateInfo";
//import { AuthNav } from "./AuthNav";
import { Flex } from "reflexbox";
//import  CastNCarry  from "../CastNCarry/CastNCarry";
import  CastNCarrySubscribe  from "../CastNCarry/CastNCarrySubscribe";
//import MuxVideoPlayer from "../MuxPlayer/MuxPlayer";
import ProjectDataList from "./ProjectDataList";
//import { EventDateInfo } from "../components/EventDateInfo";
//import AuthNavExtend from "./AuthNavExtend";
import '@fontsource/inter/variable.css';
import "../App.css";


  {/* 
  const projectData = [
  {
      id: '01',
      annyTracking: 'ANNY1881',
      title: 'Pale Blue Dot',
      duration: '3:59',
      countryOrigin: 'Singapore',
      imageURL: 'https://airtable.com/appkOPmcUZGIbRIK1/tblpku9QU4UHa4E44/recSHgpzuEBpaZ8sP/fldlZYQ1IdmLplMLR/attUrLBjDk0fpNzra',
  },
  {
      id: '02',
      annyTracking: 'ANNY5306',
      title: 'HYBRIDS',
      duration: '6:22',
      countryOrigin: 'France',
      imageURL: 'https://airtable.com/appkOPmcUZGIbRIK1/tblpku9QU4UHa4E44/reccbtCUMwXIYzA1Q/fldlZYQ1IdmLplMLR/attH9cvqAcZ634DoV',
  },
  {
      id: '03',
      annyTracking: 'ANNY2510',
      title: 'Habitat',
      duration: '2:20',
      countryOrigin: 'Switzerland',
      imageURL: 'https://airtable.com/appkOPmcUZGIbRIK1/tblpku9QU4UHa4E44/recckOIubx12AMXfz/fldlZYQ1IdmLplMLR/attBfY33IrN2x5abf',
  },
];
*/} 

export function ANNYExchangeClient() {
  
  const { route } = useAuthenticator((context) => [context.route]);
  const message =
    route === "authenticated" ? " " : "Loading...";
  return <div level={1}>
  {message}
    <div className="WrappedContent">
      <Container fluid className="AppContainerContent">
        <Flex
            mt="100px"
            alignItems="flex-start"
            //width={1}
            style={{ border: "1px solid #0033FF" }}
            >
          <Flex width="100%">
            {/* */}
            <CastNCarrySubscribe />
            
            {/* 
            <MuxVideoPlayer />
            */}
          </Flex>
        </Flex>
        {/* */}
        <Table responsive variant="dark" size="sm" striped className="projectList" >
          <thead>
            <tr>
              <th colSpan="5" className="projectListTitle">GALLERY EXHIBIT: INDEPENDENT MOTION</th>
            </tr>
            {/* 
            <tr>
              <th>ID</th>
              <th>Image</th>
              <th>Title</th>
              <th>Country</th>
              <th>Time</th>
            </tr>
            */}
          </thead>
          <tbody>
          
          </tbody>
             {/*  <ProjectDataList /> */}
        </Table>
        
      </Container>
    </div>
  </div>;
}
