import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Navbar, Nav, Button } from 'react-bootstrap';
import { 
  useAuthenticator, 
  //View 
  } from "@aws-amplify/ui-react";
import Container from "react-bootstrap/Container";
import './Navbar/Navbar.css';
import '@fontsource/inter/variable.css';
import '../App.css';


export function NavLayout() {
  
  const { route, signOut } = useAuthenticator((context) => [ 
    context.route, 
    context.signOut, 
  ]);
  
  const navigate = useNavigate();

  
  function logOut() {
    signOut();
    navigate("/login");
  }
  
  return (
    <>
      {/* 
      <div className="loginStatusMessage">
      <View>
        {route === "authenticated" ? "Welcome!": "Please Login!"}
      </View>
      </div>
       */}
      <Navbar variant="dark" expand="lg">
          <Container fluid>
            {/* LOGO NAV */}
            <Navbar.Brand href="/">
              <img
                //width="200px"
                //width="150px"
                width="80px"
                height="100%"
                src="https://s3.amazonaws.com/animationnights.media/images/logo512.png"
                alt="Logo"
                className="navLogoImg"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav 
                className="ml-auto navbar-brand mb-0 h1"
                style={{ maxHeight: '300px' }}
              >  
                {/* */}
                  {route !== "authenticated" ? (
                <> 
                {/*  */}
                <Button className="navButtonLog" variant="outline-light" onClick={() => navigate("/login")}>LOGIN</Button>
                </>
                  ) : (
                <>
                {/*
                <Button className="navButton" variant="outline-light" onClick={() => navigate("/")}>
                  HOME
                </Button>
                <Button className="navButton" variant="outline-light" onClick={() => navigate("/creator")}>
                  PROFILE
                </Button>
                <Button className="navButton" variant="outline-light" onClick={() => navigate("/admin")}>
                  ADMIN
                </Button>
                <Button className="navButton" variant="outline-light" onClick={() => navigate("/subscriber")}>
                      SPECIAL PROGRAM
                </Button>
                */}
                <Button className="navButton" variant="outline-light" onClick={() => navigate("/watchshortfilm")}>
                  WATCH FILMS
                </Button>
                {/*  
                <Button className="navButton" variant="outline-light" onClick={() => navigate("/votingapp")}>
                  VOTE
                </Button>
                */}
                <Button className="navButton" variant="outline-light" onClick={() => window.open("http://poll.anny.nyc", "_blank")}>
                  VOTE
                </Button>
                 
                <Button className="navButton" variant="outline-light" onClick={() => navigate("/annyxr")}>
                  VISIT VR SPACE
                </Button>
                <Button className="navButtonLog" variant="outline-light" onClick={() => logOut()}>LOGOUT</Button>
                </>
                  )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div level={1}>
        </div>
      <Outlet />
    </>
  );
}
